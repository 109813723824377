import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';
import { distinctUntilChanged, switchMap, flatMap, map, takeUntil } from 'rxjs/operators';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Project, TasksService, Task, Logger, FeedEntry, MyTimesService } from 'timeghost-api';
import { BehaviorSubject, Subject, of, throwError, forkJoin } from 'rxjs';
import { ElRefDirective } from '@app/_directives/el-ref/el-ref.directive';
import { extract } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@app/app.service';
import { CustomValidators } from '@app/_validators/custom-validators';
import { parse, addDays, differenceInDays, startOfDay, isSameDay, isAfter, differenceInMinutes } from 'date-fns/esm';
import { parse as fpParse, isAfter as fpIsAfter, differenceInHours, format } from 'date-fns/esm/fp';
import { flow } from 'lodash-es';

const log = new Logger('CalendarInsertTimeDialog');

@Component({
  selector: 'app-calendar-insert-time-dialog',
  templateUrl: './calendar-insert-time-dialog.component.html',
  styleUrls: ['./calendar-insert-time-dialog.component.scss'],
})
export class CalendarInsertTimeDialogComponent implements OnInit {
  private _onDestroy = new Subject<void>();
  private _isLoading = new BehaviorSubject<boolean>(false);
  readonly isLoading$ = this._isLoading.asObservable().pipe(distinctUntilChanged());
  get isLoading() {
    return this._isLoading.getValue();
  }
  set isLoading(val: boolean) {
    this._isLoading.next(val);
  }

  private _data = new BehaviorSubject<CalendarInsertTiemDialogData>(null);
  readonly data$ = this._data.asObservable().pipe(distinctUntilChanged());
  readonly daySelector$ = this.data$.pipe(
    map((x) => x.event),
    map((x) => {
      const start = new Date(x.start),
        end = new Date(x.end);
      if (differenceInDays(end, start) <= 1) return null;
      const range = [];
      for (let _start = new Date(start.getTime()); !isAfter(_start, end); addDays(_start, 1)) {
        range.push(startOfDay(new Date(_start.getTime())));
      }
      return range;
    })
  );
  get data() {
    return this._data.getValue();
  }
  set data(val: CalendarInsertTiemDialogData) {
    this._data.next(val);
  }
  insertGrp: UntypedFormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    dialogData: CalendarInsertTiemDialogData,
    private ref: MatDialogRef<CalendarInsertTimeDialogComponent>,
    private myTimesservice: MyTimesService,
    private taskService: TasksService,
    private translateService: TranslateService,
    private appService: AppService,
    private translate: TranslateService
  ) {
    this.data = dialogData;
    this.insertGrp = new UntypedFormGroup(
      {
        name: new UntypedFormControl(dialogData.event.name, [Validators.required, CustomValidators.minLength(3)]),
        billable: new UntypedFormControl(!!dialogData.project?.billable, [Validators.required]),
      },
      [CustomValidators.timeDiffCheck('start', 'end')]
    );
    log.debug(dialogData);
    this.insertGrp.addControl(
      'start',
      new UntypedFormControl(flow(format('HH:mm'))(new Date(dialogData.event.start)), [Validators.required])
    );
    this.insertGrp.addControl(
      'end',
      new UntypedFormControl(flow(format('HH:mm'))(new Date(dialogData.event.end)), [Validators.required])
    );
    if (
      dialogData.event.type === 'calendar' &&
      // @ts-ignore
      flow(fpParse(new Date()), differenceInHours(new Date(dialogData.event.start)))(dialogData.event.end) > 24
    ) {
      this.insertGrp.addControl(
        'selectedDay',
        new UntypedFormControl(startOfDay(new Date(dialogData.event.start)), [Validators.required])
      );
      this.insertGrp.updateValueAndValidity();
    }
    this.insertGrp.updateValueAndValidity();
  }

  ngOnInit() {
    this.ref.updateSize('420px').addPanelClass(['calendar-insert-dialog', 'mat-dialog-relative']);
  }
  selectedDayCompare(l: Date, r: Date) {
    return isSameDay(l, r);
  }
  addTime(data: typeof UntypedFormGroup.prototype.value, { project, event }: CalendarInsertTiemDialogData) {
    let startEvent: Date = this.insertGrp.value?.selectedDay || new Date(event.start),
      endEvent: Date = this.insertGrp.value?.selectedDay || new Date(event.end);

    const start = parse(data.start, 'HH:mm', Date.now()),
      end = parse(data.end, 'HH:mm', Date.now());
    if (differenceInMinutes(start, end) > 0) {
      this.appService.notifier.show({
        type: 'error',
        message: this.translate.instant('errors.record.time-invalid-range'),
      });
      return;
    }
    this.isLoading = true;

    return this.myTimesservice
      .add({
        project: { id: project.id },
        name: data.name,
        start: new Date(startEvent.setHours(start.getHours(), start.getMinutes(), 0, 0)),
        end: new Date(startEvent.setHours(end.getHours(), end.getMinutes(), 0, 0)),
        billable: data.billable,
        outlookCalenderReference: event.id,
        tags: [] as any,
      })

      .subscribe(
        (x) => {
          log.debug(x);
          this.appService.notifier.notify('success', this.translateService.instant(extract('feed.time-saved')));
          this.ref.close({ feedEntry: this.data.event, time: Array.isArray(x) ? x[0] : x });
        },
        (err) => {
          log.error(err);
          if (err?.errors) {
            const error = err.errors[Object.keys(err.errors)[0]][0];
            this.appService.notifier.notify('error  ', error);
          }
          this.isLoading = false;
        }
      );
  }
  onSubmit() {
    return this.addTime({ ...this.insertGrp.value }, { ...this.data });
  }

  selectInput(ev: Event, timeInput: ElRefDirective) {
    const el: HTMLElement = timeInput.elementRef.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
  resetTaskName() {
    this.insertGrp.setValue({
      ...this.insertGrp.value,
      // @ts-ignore
      name: this.data.event.name,
    });
  }
}
export interface CalendarInsertTiemDialogData {
  project: Project;
  task: Task;
  event: FeedEntry;
}
