<tg-toolbar tbclass="mat-toolbar-tg mat-toolbar-base flex !h-initial flex-row gap-2 sm:gap-3 py-3 md:py-0">
  <ng-template tgToolbarControls>
    <div
      class="flex flex-auto flex-col items-center justify-start gap-1 sm:flex-row sm:items-center sm:justify-start sm:gap-3"
    >
      <div *ngIf="viewDate$status | async as vd" class="flex flex-row items-center justify-start gap-1">
        <button mat-icon-button (click)="prev()" [disabled]="(this.isLoading$ | async) || minValidation">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button
          mat-button
          class="mt-1 space-x-1"
          [satPopoverAnchor]="dayPickerPopover"
          (click)="dayPickerPopover.toggle()"
          [disabled]="this.isLoading$ | async"
        >
          <ng-container *ngIf="vd.sameDay; else vdRange">
            <span>{{ vd.start | formatDate: 'EEEE' }}</span>
            <span>{{ vd.start | formatDate: 'PP' }}</span>
            <span>({{ 'timer.calendar.kw' | translate: { value: vd.start | formatDate: 'I' } }})</span>
          </ng-container>
          <ng-template #vdRange>
            <span>{{ vd.start | formatDate: 'PP' }} - {{ vd.end | formatDate: 'PP' }}</span>
            <span>({{ 'timer.calendar.kw' | translate: { value: vd.start | formatDate: 'I' } }})</span>
          </ng-template>
        </button>
        <button mat-icon-button (click)="next()" [disabled]="this.isLoading$ | async">
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button
          mat-button
          fxHide.lt-md
          class="mt-1"
          (click)="today()"
          [disabled]="(this.isLoading$ | async) || vd.isToday"
        >
          <mat-icon>today</mat-icon>
          <span class="ml-2">{{ 'timer.calendar.today' | translate }}</span>
        </button>
      </div>
      <div class="flex-auto"></div>
      <div class="flex flex-row items-center justify-start gap-2">
        <div
          class="mat-bg-3 mat-bg-5-hover flex h-10 cursor-pointer select-none items-center space-x-4 rounded px-2.5"
          (click)="$event.preventDefault(); $event.stopPropagation(); toggleRange()"
        >
          <mat-icon [class.opacity-60]="rangeType !== 'day'" [matTooltip]="'utils.dayView' | translate"
            >view_day</mat-icon
          >
          <mat-slide-toggle [checked]="rangeType === 'week'" color="primary"></mat-slide-toggle>
          <mat-icon [class.opacity-60]="rangeType !== 'week'" [matTooltip]="'utils.weekView' | translate"
            >view_week</mat-icon
          >
        </div>
        <button
          mat-icon-button
          *ngIf="filteredEntries$hasHidden | async"
          [matTooltip]="'feed.hidden-items' | translate"
          (click)="openFeedHiddenDialog()"
        >
          <mat-icon>visibility_off</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="$event.preventDefault(); changeShowBooked()"
          [matTooltip]="'feed.booked.show' | translate"
          class="flex flex-row items-center justify-center"
        >
          <ng-container *ngIf="!showBookedLoading; else loadingShowBooked">
            <mat-checkbox [checked]="this.showBooked$ | async" color="primary"></mat-checkbox>
          </ng-container>
          <ng-template #loadingShowBooked>
            <mat-spinner color="primary" mode="indeterminate" [diameter]="18"></mat-spinner>
          </ng-template>
        </button>
        <button mat-icon-button [matTooltip]="'account.profile.feed-grant' | translate" [routerLink]="['feed-scopes']">
          <mat-icon>settings</mat-icon>
        </button>
        <button
          mat-icon-button
          *ngIf="!(this.isLoading$ | async); else reloadCalLoading"
          (click)="this.reloadCalendar()"
          [matTooltip]="'feed.refresh' | translate"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <ng-template #reloadCalLoading>
      <div class="flex flex-col items-center justify-center" style="width: 40px; height: 40px">
        <mat-progress-spinner [strokeWidth]="2" [diameter]="20" color="primary" [mode]="'indeterminate'">
        </mat-progress-spinner>
      </div>
    </ng-template>
  </ng-template>
</tg-toolbar>
<div class="container-wrapper">
  <div class="container-content calendar-items flex flex-col items-stretch justify-start pb-0">
    <div class="flex h-full justify-start">
      <!-- calendar -->
      <div class="flex flex-auto flex-col">
        <div class="mat-bg-3 mat-bd-b-8 flex flex-col pt-5">
          <mat-form-field class="mx-6" appearance="fill">
            <mat-label translate>utils.search</mat-label>
            <input matInput [formControl]="this.eventFilterSearch" />
            <button mat-icon-button matSuffix type="button" [matMenuTriggerFor]="eventSearchFilterMenu">
              <mat-icon>filter_list</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <ng-container *ngIf="this.requestError$ | async as err">
          <div class="alert alert-danger rounded-0 flex flex-col">
            <small>
              {{ err.message || 'Something went wrong!' | translate }}
            </small>
            <div class="mt-1" *ngIf="err.isTimeout || !err.isAuthed">
              <button mat-flat-button class="mat-button-small" (click)="locationReload()">
                {{ 'utils.reload' | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <div class="feed-container-wrapper">
          <div class="feed-container-content">
            <ng-container *ngIf="filteredEntries$ | async as entries">
              <ng-container
                *ngTemplateOutlet="
                  (isLoading$ | async) ? loadingCards : entries.length > 0 ? entriesTemplate : noResultsTemplate;
                  context: { entries: entries }
                "
              ></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #nullSubject let-entry>
  <h3 class="entry-subject my-0 ml-1 truncate font-bold">
    {{ 'feed.emptySubject' | translate }}
  </h3>
</ng-template>
<ng-template #existSubject let-entry>
  <h3 class="entry-subject my-0 ml-1 truncate font-bold">{{ entry.name }}</h3>
</ng-template>
<ng-template #loadingCards>
  <div class="calGroup">
    <!-- date indicator -->
    <div class="calGroupEntries">
      <!-- calendar entry -->
      <div class="calendar-items flex flex-col items-stretch justify-start">
        <ng-container *ngFor="let _i of [0, 0, 0, 0]; let ceLast = last">
          <mat-card class="calEntry calendar-item !py-1">
            <mat-card-content class="calEntryContent !px-3">
              <div class="flex flex-col gap-2">
                <div class="mat-skeleton" style="width: 150px"></div>
                <div style="width: 80px" class="mat-skeleton"></div>
                <div style="width: 180px" class="mat-skeleton"></div>
              </div>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #calendarEntryExample>
  <mat-card class="calEntry calEntryExample">
    <div class="calEntryExamplePan">
      <mat-icon>pan_tool</mat-icon>
    </div>
    <mat-card-content>
      <div class="calEntryContent flex flex-row items-center justify-start">
        <div class="flex flex-grow flex-col items-start justify-start gap-[2px]">
          <div class="flex flex-row items-center justify-start gap-2">
            <h4 class="my-0 ml-1">{{ 'utils.example' | translate }}</h4>
          </div>
          <small class="mat-hint ml-1">{{ 'feed.draggable-notice' | translate }}</small>
        </div>
        <div class="flex-grow"></div>
        <div class="flex items-center">
          <mat-icon>branding_watermark</mat-icon>
        </div>
        <div class="flex flex-col justify-center" (mousedown)="$event.stopPropagation()">
          <button mat-icon-button disabled>
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
<router-outlet></router-outlet>
<mat-menu #eventSearchFilterMenu="matMenu" class="event-filter !pb-2 !pt-0">
  <ng-template matMenuContent let-data>
    <mat-list (click)="$event.stopPropagation()" class="event-filter-list !py-0">
      <ng-container *ngIf="availableScopes$ | async as enabledScopes">
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.Calendar)"
          [title]="'account-scope.' + scopes.Calendar"
          image="/assets/img/logos/calendar_logo.png"
          [checked]="enabledScopes.enabled(scopes.Calendar)"
          (change)="this.toggleFilterScope(scopes.Calendar)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.OutlookTasks)"
          [title]="'account-scope.' + scopes.OutlookTasks"
          image="/assets/img/logos/todo_logo.png"
          [checked]="enabledScopes.enabled(scopes.OutlookTasks)"
          (change)="this.toggleFilterScope(scopes.OutlookTasks)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.RecentFiles)"
          [title]="'account-scope.' + scopes.RecentFiles"
          image="/assets/svg/office/onedrive_48x1.svg"
          [checked]="enabledScopes.enabled(scopes.RecentFiles)"
          (change)="this.toggleFilterScope(scopes.RecentFiles)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.Sites)"
          [title]="'account-scope.' + scopes.Sites"
          image="/assets/svg/office/sharepoint_48x1.svg"
          [checked]="enabledScopes.enabled(scopes.Sites)"
          (change)="this.toggleFilterScope(scopes.Sites)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.Messages)"
          [title]="'account-scope.' + scopes.Messages"
          image="https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product-fluent/png/outlook_48x1.png"
          [checked]="enabledScopes.enabled(scopes.Messages)"
          (change)="this.toggleFilterScope(scopes.Messages)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.Groups)"
          [title]="'account-scope.' + scopes.Groups"
          image="/assets/img/logos/planer_logo.png"
          [checked]="enabledScopes.enabled(scopes.Groups)"
          (change)="this.toggleFilterScope(scopes.Groups)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.DevOpsOnline)"
          title="account-scope.DevOpsOnline.ReadBasic"
          image="/assets/img/logos/devops_logo.png"
          [checked]="enabledScopes.enabled(scopes.DevOpsOnline)"
          (change)="this.toggleFilterScope(scopes.DevOpsOnline)"
        ></app-feed-filter-event-item>
        <app-feed-filter-event-item
          *ngIf="enabledScopes.includes(scopes.TeamsCalls) && scopes.TeamsCalls as scope"
          [title]="'account-scope.' + scope"
          image="/assets/svg/icons/teams.svg"
          [checked]="enabledScopes.enabled(scope)"
          (change)="this.toggleFilterScope(scope)"
        ></app-feed-filter-event-item>
        <ng-container *ngIf="this.availableScopes$custom | async as customScopes">
          <mat-divider class="my-2" inline></mat-divider>
          <h4 mat-subheader>Custom Types</h4>
          <app-feed-filter-event-item
            *ngFor="let scope of customScopes.scopes"
            [title]="scope.key | parseCustomScope"
            [checked]="enabledScopes.enabled(scope.key)"
            (change)="toggleFilterScope(scope.key)"
          ></app-feed-filter-event-item>
        </ng-container>
      </ng-container>
      <mat-divider class="my-2" inline></mat-divider>
      <div matRipple class="cursor-pointer" [routerLink]="['feed-scopes']">
        <div mat-list-item class="event-filter-item flex flex-row items-center justify-start gap-3">
          <span translate>account-scope.connect</span>
        </div>
      </div>
    </mat-list>
  </ng-template>
</mat-menu>
<mat-menu #projectMenu>
  <ng-template matMenuContent let-project="project">
    <button mat-menu-item (click)="this.togglePinProject(project.id)">
      <mat-icon inline>star</mat-icon>
      {{ (this.isProjectPinned(project.id) ? 'project.fav-disable' : 'project.fav-enable') | translate }}
    </button>
    <a mat-menu-item [routerLink]="['/settings/projects', project.id]" *ngIf="!project.useAsDefault">
      <mat-icon inline>folder</mat-icon> {{ 'project.open' | translate }}
    </a>
  </ng-template>
</mat-menu>
<tg-day-picker-popover
  #dayPickerPopover
  [min]="minRangeStart"
  [viewDate]="viewDate$ | async"
  (viewDateChange)="updateRange($event)"
  [rangeType]="rangeType"
></tg-day-picker-popover>
<ng-template #noResultsTemplate>
  <div class="flex h-32 flex-col items-stretch justify-center gap-2" *ngIf="!(isLoading$ | async)">
    <div class="flex flex-row items-center justify-center gap-2">
      <span class="mat-hint" *ngIf="viewDate$ | async as vd"
        >{{ vd.start | formatDate: 'PP' }} - {{ vd.end | formatDate: 'PP' }}</span
      >
    </div>
    <span class="text-center" translate *ngIf="this.eventFilterSearch.value">feed.search.noitems</span>
    <span class="text-center" translate *ngIf="!this.eventFilterSearch.value">utils.noitems</span>
  </div>
</ng-template>
<ng-template #entriesTemplate let-entries="entries">
  <ng-container
    *ngFor="let entry of entries; let ceIndex = index; let ceFirst = first; let ceLast = last; trackBy: trackId"
  >
    <mat-card
      class="calEntry calendar-item cursor-pointer !py-0"
      (contextmenu)="openFeedEntryContextMenu($event, feedEntryMenuTrigger, { entry: entry })"
      (click)="drop({ data: entry })"
    >
      <mat-card-content>
        <div class="calEntryContent flex flex-row items-center justify-start">
          <div class="flex-text flex flex-grow flex-col items-start justify-start gap-2">
            <div class="flex-text flex flex-row items-center justify-start gap-2 pb-1">
              <div *ngIf="entry.booked" class="ml-1 flex-none leading-none">
                <mat-icon class="mat-icon-complete p-1" inline>check</mat-icon>
              </div>
              <ng-container *ngTemplateOutlet="entry.name ? existSubject : nullSubject; context: { $implicit: entry }">
              </ng-container>
            </div>
            <div class="mat-fill-card-content px-1" *ngIf="entry.description as desc">
              {{ desc | parseFeedDescription | truncate: 120 }}
            </div>
            <div class="flex w-full flex-row items-center justify-start gap-1.5">
              <ng-container *ngIf="!entry.isAllDay">
                <div class="badge flex flex-row items-center justify-start gap-1.5" ngClass.lt-sm="badge-sm">
                  <span>{{ entry.start | dfnsParseIso | dfnsFormat: 'dd. MMMM - EEEE' }},</span>
                  <span>{{ entry.start | dfnsParseIso | formatDate: 'time' }}</span>
                  <span>-</span>
                  <span>{{ entry.end | dfnsParseIso | formatDate: 'time' }}</span>
                </div>
              </ng-container>
              <ng-container *ngIf="entry.isAllDay">
                <div class="badge flex flex-row items-center justify-start gap-1.5">
                  {{ 'feed.all-day' | translate }}
                </div>
              </ng-container>
              <div class="badge flex flex-row items-center justify-start gap-1.5" *ngIf="entry.calendar as calendar">
                <div
                  class="rounded-circle -mt-px mr-1 h-3 w-3"
                  *ngIf="calendar.color as color"
                  [style.backgroundColor]="color"
                ></div>
                <span>{{ calendar.name }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-shrink-0 items-center">
            <img *ngIf="entry.iconUrl != null; else msIcon" [src]="entry.iconUrl" style="width: 32px" />
            <ng-template #msIcon>
              <ms-icon style="font-size: 32px; height: 32px; width: 32px" [icon]="entry.icon"> </ms-icon>
            </ng-template>
          </div>
          <div
            class="flex flex-shrink-0 flex-col justify-center"
            (mousedown)="$event.stopPropagation()"
            (click)="$event.stopPropagation()"
          >
            <button
              mat-icon-button
              (click)="
                openFeedEntryContextMenu($event, feedEntryMenuTrigger, {
                  entry: entry
                })
              "
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-template>
<mat-menu #feedEntrySuggestionsMenu class="flex-mat-menu">
  <ng-template matMenuContent let-suggestions="suggestions" let-feedItem="entry">
    <div class="flex flex-col gap-2">
      <ng-container *ngFor="let project of suggestions | slice: 0:10; trackBy: trackId">
        <button
          mat-menu-item
          *ngFor="let task of project.tasks; trackBy: trackId"
          (click)="submitFeedItem(feedItem, project.id, task, !!$event.ctrlKey)"
          [disabled]="isLoading"
        >
          <div class="flex flex-col space-y-1">
            <span>{{ task.id === '_internal' ? (task.name | translate) : task.name }}</span>
            <span class="mat-hint text-xs">{{ project | defaultEntityTranslate: 'project':'project.none' }}</span>
          </div>
        </button>
      </ng-container>
    </div>
  </ng-template>
</mat-menu>
<mat-menu #feedEntryContextMenu>
  <ng-template
    matMenuContent
    let-entry="entry"
    let-suggestions="suggestions"
    let-suggestionsLoading="suggestionsLoading"
  >
    <div class="flex flex-col">
      <a mat-menu-item [href]="entry.link" target="_blank" [disabled]="!entry.link">{{
        'feed.entry.open' | translate
      }}</a>
      <ng-container *ngIf="!suggestionsLoading; else suggestionsLoadingTemplate">
        <button
          mat-menu-item
          [matMenuTriggerFor]="feedEntrySuggestionsMenu"
          [matMenuTriggerData]="{ suggestions: suggestions, entry: entry }"
          [disabled]="!suggestions"
        >
          {{ 'utils.suggestions' | translate }}
        </button>
      </ng-container>
      <button mat-menu-item (click)="toggleHide(entry)" [disabled]="hideLoading">
        {{ 'feed.entry.hide' | translate }}
      </button>
    </div>
  </ng-template>
</mat-menu>
<div
  #feedEntryMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="feedEntryContextMenu"
  style="visibility: hidden; position: fixed"
  [style.left]="feedEntryMenuPosition.x"
  [style.top]="feedEntryMenuPosition.y"
></div>
<ng-template #suggestionsLoadingTemplate>
  <button mat-menu-item (click)="$event.preventDefault()" [disabled]="true">
    <div class="flex items-center space-x-2">
      <mat-spinner mode="indeterminate" [diameter]="16" [strokeWidth]="2"></mat-spinner>
      <div class="flex items-center space-x-2">
        {{ 'utils.suggestions' | translate }}
      </div>
    </div>
  </button>
</ng-template>
